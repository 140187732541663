import localisable from "../../../../common/constants/localisable";
import { capitalize } from "../../../../common/helpers/utils/DataHelper";

const formatPerformanceMetricData = (
  data,
  activeSubPlatformTab,
  activeCategoryTab,
  mediaPlatform,
) => {
  const formattedData = [];
  for (const [type, details = {}] of Object.entries(data)) {
    const { low_score, average_score, good_score, excellent_score } = details
    const scores = [low_score, average_score, good_score, excellent_score];
    const numericScores = scores.map(score => !score ? 0 : parseFloat(score));

    // Check for ascending and descending order
    const isAscending = numericScores.every(
      (score, i, arr) => i === 0 || arr[i - 1] <= score
    );
    const id = details.id;
    const metric_field = details.metric_field ?? null;

    const configItem = {
      ...(id && { id }),
      sub_media_platform: activeSubPlatformTab,
      use_platform_metric: details.use_platform_metric || false,
      category: activeCategoryTab,
      type,
      metric_field,
      excellent_score: parseFloat(details.excellent_score) ?? null,
      good_score: parseFloat(details.good_score) ?? null,
      average_score: parseFloat(details.average_score) ?? null,
      low_score: parseFloat(details.low_score) ?? null,
      is_increasing_score: isAscending,
      media_platform: mediaPlatform,
    };
    formattedData.push(configItem);
  }
  return formattedData;
};

const getSubMediaPlatform = (platform) => {
  return platform.toLowerCase();
};

const validateScores = (obj, performanceMetricFieldPath, setShowValidationError, setErrorMessage, setFormData) => {
  const { low_score, average_score, good_score, excellent_score, use_platform_metric, metric_field } = obj;
  const scores = [low_score, average_score, good_score, excellent_score];

  // Check for missing scores
  const isMissingScore = scores.some(
    (score) => score === "" || score === null || score === undefined
  );
  if (!use_platform_metric && isMissingScore && (metric_field !== 'not_applicable' || metric_field === '')) {
    setShowValidationError(true);
    setErrorMessage(`All score fields must be filled for ${capitalize(performanceMetricFieldPath.split('.').join('-> '), true)}`);
    setTimeout(() => {
      setShowValidationError(false);
    }, 3000);
    return false;
  }

  // Convert scores to numbers
  const numericScores = scores.map(score => !score ? 0 : parseFloat(score));

  // Check for ascending and descending order
  const isAscending = numericScores.every(
    (score, i, arr) => i === 0 || arr[i - 1] <= score
  );
  const isDescending = numericScores.every(
    (score, i, arr) => i === 0 || arr[i - 1] >= score
  );
  if (!isAscending && !isDescending) {
    setShowValidationError(true);
    setErrorMessage(localisable.incorrectScoreOrder);
    setTimeout(() => {
      setShowValidationError(false);
    }, 3000);
    return false;
  }
  return true;
};

const isTwoDecimalPlaces = value => ((value && !(/^-?\d*(\.\d{0,2})?$/.test(value))) ? localisable.twoDecimalPlaces : undefined);

/**
 * Determines if the provided asset type form data contains valid performance metric values.
 * 
 * This function checks whether the given form data for an asset type (e.g., image, link, PDF) 
 * includes any valid metric-related fields. It looks for specific keys (`use_platform_metric`, 
 * `metric_field`, and keys that end in `_score` but are not `is_increasing_score`) and returns 
 * `true` if any of these fields have a truthy value.
 * 
 * @param  {Object} formTypeData - The form data object for a specific asset type, where keys 
 *                                 represent form fields and values represent user input.
 * @return {boolean} - Returns `true` if the form data contains valid metric fields, otherwise `false`.
 */
const containsValidMetric = (formTypeData = {}) => {
  return Object.entries(formTypeData).some(([key, value]) => {
    return (
      value &&
      (
        (key === 'use_platform_metric' && value) ||
        key === 'metric_field' ||
        (key.includes('_score') && key !== 'is_increasing_score')
      )
    );
  });
}


/**
 * Counts asset types with valid performance metric data in a category.
 *
 * Iterates over the form data for each asset type in a category and counts how many have valid
 * metric fields, as determined by `containsValidMetric`.
 *
 * @param  {Object} formCategoryData - The form data for various asset types within a category.
 * @return {number} - The count of asset types with valid metric fields.
 */
const getFormDataTypeCount = (formCategoryData = {}) => {
  return Object.values(formCategoryData).reduce((count, typeData) => {
    return count + (containsValidMetric(typeData) ? 1 : 0);
  }, 0);
}

export {
  formatPerformanceMetricData,
  getSubMediaPlatform,
  isTwoDecimalPlaces,
  validateScores,
  getFormDataTypeCount,
  containsValidMetric,
};
